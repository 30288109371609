import EgalActionConstructor from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'
import { GetItemsResponse, ICompanyDocument } from '@/types/interfaces'

class CompanyDocumentAPIModel {
  async getItems(
    filters: any = [],
    perPage = 10,
    withs: string[] = [],
  ): Promise<GetItemsResponse<ICompanyDocument>> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.CompanyDocument,
      )
        .filter(filters)
        .setPagination(perPage, 1)
        .withs(withs)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getItem(id: string | number): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItem(
        Microservices.Core,
        APIModels.CompanyDocument,
        id,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async upload(data: {
    file_name: string
    extension: string
    file_body: string
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.custom(
        Microservices.Core,
        APIModels.CompanyDocument,
        'upload',
        data,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async createMany(
    data: {
      order_id: number
      file_path: string
    }[],
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.createMany(
        Microservices.Core,
        APIModels.CompanyDocument,
        data,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const CompanyDocumentAPI = new CompanyDocumentAPIModel()
export { CompanyDocumentAPI }
