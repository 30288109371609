import EgalActionConstructor from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'
import { Document, ProfileDocumentCreate } from '@/types/types'

class ProfileDocumentAPIModel {
  async uploadDocument(data: Document): Promise<any> {
    data.file_body = data?.file_body?.split(',')[1]
    data.extension = data.file_name.split('.').slice(-1)[0]
    data.file_name = data?.file_name.replace(data.extension, '')

    return new Promise((resolve, reject) => {
      return EgalActionConstructor.custom(
        Microservices.Core,
        APIModels.CompanyDocument,
        'upload',
        data,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getItems(
    filters: any = [],
    perPage = 10,
    withs: string[] = [],
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.CompanyDocument,
      )
        .filter(filters)
        .setPagination(perPage, 1)
        .withs(withs)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getItem(id: string | number): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItem(
        Microservices.Core,
        APIModels.CompanyDocument,
        id,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async updateMany(data: Partial<Document>[]): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.updateMany(
        Microservices.Core,
        APIModels.CompanyDocument,
        data,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async createManyProfileDocuments(
    data: Document[] | ProfileDocumentCreate[],
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.createMany(
        Microservices.Core,
        APIModels.CompanyDocument,
        data,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const ProfileDocumentAPI = new ProfileDocumentAPIModel()
export { ProfileDocumentAPI }
