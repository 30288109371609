export const primaryButton = {
  backgroundColor: '#0066FF',
  fontWeight: 500,
  color: '#FFFFFF',
  fontFamily: 'Inter',
  hover: {
    backgroundColor: '#3385FF',
  },
  active: {
    backgroundColor: '#005CE4',
  },
  disabled: {
    backgroundColor: '#E2E8F0',
    color: '#A0AEC0',
    cursor: 'default',
  },
}

export const secondaryButton = {
  backgroundColor: '#F8F7F5',
  color: '#2D3748',
  hover: {
    backgroundColor: '#F0EEEB',
  },
  active: {
    backgroundColor: '#B6B6B6',
  },
  disabled: {
    backgroundColor: '#E2E8F0',
    color: '#A0AEC0',
    cursor: 'not-allowed',
  },
}

export const secondaryBlueButton = {
  backgroundColor: '#DEEBFC',
  color: '#0066FF',
  hover: {
    backgroundColor: '#A6C8FA',
  },
  active: {
    backgroundColor: '#76ACFB',
  },
  disabled: {
    backgroundColor: '#E2E8F0',
    color: '#A0AEC0',
    cursor: 'not-allowed',
  },
}

export const primaryButtonWithoutDisable = {
  backgroundColor: '#0066FF',
  borderColor: '#0066FF',
  borderStyle: 'solid',
  borderWidth: '1px',
  fontWeight: 500,
  color: '#FFFFFF',
  fontFamily: 'Inter',
  hover: {
    borderColor: '#0066FF',
  },
  active: {
    borderColor: '#0066FF',
  },
}

export const withoutBGButton = {
  backgroundColor: 'transparent',
  color: '#0066FF',
  hover: {
    color: '#3385FF',
  },
  active: {
    color: '#005CE4',
  },
  disabled: {
    color: '#A0AEC0',
    cursor: 'not-allowed',
  },
}

export const outlineButton = {
  backgroundColor: 'transparent',
  borderColor: '#0066FF',
  borderStyle: 'solid',
  borderWidth: '1px',
  color: '#0066FF',
  fontFamily: 'Inter',
  hover: {
    backgroundColor: 'transparent',
    borderColor: '#0066FF',
    color: '#0066FF',
  },
  active: {
    backgroundColor: 'transparent',
    borderColor: '#0066FF',
    color: '#0066FF',
  },
  disabled: {
    backgroundColor: 'transparent',
    borderColor: '#A0AEC0',
    color: '#A0AEC0',
    cursor: 'not-allowed',
  },
}

export const outlineSuccessButton = {
  backgroundColor: 'transparent',
  borderColor: '#66cb9f',
  borderStyle: 'solid',
  borderWidth: '1px',
  color: '#11998E',
  fontFamily: 'Inter',
  justifyContent: 'center',
  hover: {
    backgroundColor: 'transparent',
    borderColor: '#66cb9f',
    color: '#11998E',
  },
  active: {
    backgroundColor: 'transparent',
    borderColor: '#66cb9f',
    color: '#11998E',
  },
  disabled: {
    backgroundColor: 'transparent',
    borderColor: '#A0AEC0',
    color: '#A0AEC0',
    cursor: 'not-allowed',
  },
}

export const outlineGrayButton = {
  backgroundColor: 'transparent',
  borderColor: '#A0AEC0',
  borderStyle: 'solid',
  borderWidth: '1px',
  color: '#A0AEC0',
  fontFamily: 'Inter',
  hover: {
    backgroundColor: 'transparent',
    borderColor: '#A0AEC0',
    color: '#A0AEC0',
  },
  active: {
    backgroundColor: 'transparent',
    borderColor: '#A0AEC0',
    color: '#A0AEC0',
  },
  disabled: {
    backgroundColor: 'transparent',
    borderColor: '#A0AEC0',
    color: '#A0AEC0',
    cursor: 'not-allowed',
  },
}

export const outlineLightGrayButton = {
  backgroundColor: 'transparent',
  borderColor: '#F7FAFC',
  borderStyle: 'solid',
  borderWidth: '1px',
  color: '#FFFFFF',
  fontFamily: 'Inter',
  hover: {
    color: '#FFFFF',
  },
  active: {
    color: '#FFFFF',
  },
  disabled: {
    color: '#FFFFF',
    cursor: 'not-allowed',
  },
}

export const outlineWhiteButton = {
  backgroundColor: 'transparent',
  borderColor: '#FFFFFF',
  borderStyle: 'solid',
  borderWidth: '1px',
  color: '#FFFFFF',
  fontFamily: 'Inter',
  hover: {
    backgroundColor: 'transparent',
    borderColor: '#FFFFFF',
    color: '#FFFFFF',
  },
  active: {
    backgroundColor: 'transparent',
    borderColor: '#FFFFFF',
    color: '#FFFFFF',
  },
  disabled: {
    backgroundColor: 'transparent',
    borderColor: '#A0AEC0',
    color: '#A0AEC0',
    cursor: 'not-allowed',
  },
}

export const outlineBlackButton = {
  backgroundColor: 'transparent',
  borderColor: '#d9d9d9',
  borderStyle: 'solid',
  borderWidth: '1px',
  color: '#2D3748',
  fontFamily: 'Inter',
  hover: {
    backgroundColor: '#F7FAFC',
  },
  active: {
    backgroundColor: '#F7FAFC',
  },
  disabled: {
    color: '#A0AEC0',
    borderColor: '#E2E8F0',
    cursor: 'default',
  },
}

export const outlineButtonDanger = {
  backgroundColor: '#fff',
  borderColor: '#F16063',
  borderStyle: 'solid',
  borderWidth: '1px',
  color: '#F16063',
  hover: {
    backgroundColor: '#fff',
    borderColor: '#FF7880',
    color: '#FF7880',
  },
  active: {
    backgroundColor: '#fff',
    borderColor: '#DB373D',
    color: '#DB373D',
  },
  disabled: {
    backgroundColor: '#fff',
    borderColor: '#A0AEC0',
    color: '#A0AEC0',
    cursor: 'not-allowed',
  },
}

export const clearButton = {
  backgroundColor: 'transparent',
  color: '#1D1D23',
  hover: {
    color: '#718096',
  },
  active: {
    color: '#718096',
  },
  disabled: {
    color: '#A0AEC0',
    cursor: 'not-allowed',
  },
}

export const deleteButton = {
  backgroundColor: '#fff',
  borderColor: '#F16063',
  color: '#F16063',
  hover: {
    backgroundColor: '#fff',
    borderColor: '#F16063',
    color: '#F16063',
  },
  active: {
    backgroundColor: '#fff',
    borderColor: '#F16063',
    color: '#F16063',
  },
  disabled: {
    backgroundColor: '#fff',
    borderColor: '#F16063',
    color: '#F16063',
    cursor: 'not-allowed',
  },
}

export const orangeButton = {
  fontFamily: 'Inter',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#FE7942',
  backgroundColor: '#FE7942',
  justifyContent: 'center',
  color: '#FFFFFF',
  hover: {
    backgroundColor: '#FE7942',
    color: '#FFFFFF',
  },
  active: {
    backgroundColor: '#FE7942',
    color: '#FFFFFF',
  },
  disabled: {
    borderColor: '#E2E8F0',
    backgroundColor: '#E2E8F0',
    color: '#A0AEC0',
    cursor: 'not-allowed',
  },
}

export const underlinedWithoutBGButton = {
  backgroundColor: 'transparent',
  color: '#0066FF',
  borderBottom: '1px dashed #0066FF',
  borderRadius: '0px',
  paddingBottom: '3px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  width: 'fit-content',
  textAlign: 'left',
  hover: {
    color: '#3385FF',
    borderBottom: '1px dashed #3385FF',
  },
  active: {
    color: '#005CE4',
    borderBottom: '1px dashed #005CE4',
  },
  disabled: {
    color: '#A0AEC0',
    borderBottom: '1px dashed #A0AEC0',
  },
}
export const dangerButton = {
  backgroundColor: '#F16063',
  borderColor: '#F16063',
  borderStyle: 'solid',
  borderWidth: '1px',
  fontWeight: 500,
  color: '#FFFFFF',
  fontFamily: 'Inter',
  hover: {
    borderColor: '#F16063',
  },
  active: {
    borderColor: '#F16063',
  },
  disabled: {
    backgroundColor: '#E2E8F0',
    borderColor: '#E2E8F0',
    color: '#A0AEC0',
  },
}

export const fontSize16 = {
  fontSize: '16px',
  fontWeight: '500',
}
export const fontSize14 = {
  fontSize: '14px',
  fontWeight: '500',
}
