import { IAccountUser } from '@/types/interfaces'

export const DEFAULT_PRODUCT_CARD = {
  product_id: -1,
  price: -1,
  min_value: -1,
  quantity: -1,
  farmer_id: -1,
  measurement_unit_id: '',
  image_name: '',
  image_url: '',
  comment: '',
  prepare_days: -1,
}

export const DEFAULT_USER: IAccountUser = {
  user_id: '',
  contact_number: '',
  checking_account: '',
  description: '',
  user: {
    email: '',
  },
}

export const width100 = { width: '100%' }

export const coordinatesRegex = /^\[-?\d+\.\d+,-?\d+\.\d+\]$/

export const CONTRACT_LINK =
  'https://storage.yandexcloud.net/farm-platform-images/contract.docx'

export const PRIVACY_POLICY_LINK =
  'https://storage.yandexcloud.net/farm-platform-production-images/privacy.pdf'
export const TELEGRAM_LINK = 'https://t.me/easy_retail'
export const OWNER_EMAIL = 'kim@easy-retail.org'

export const RUBLE = '₽'
