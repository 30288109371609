export const checkboxStyleConfig = {
  borderColor: '#3385ff',
  backgroundColor: null,
  fontSize: '16px',
  hover: { borderColor: '#3385ff', backgroundColor: '#3385ff' },
  active: { borderColor: '#005ce4', backgroundColor: '#005ce4' },
  checked: { borderColor: '#0066ff', backgroundColor: '#0066ff' },
  disabled: { borderColor: '#E2E8F0', backgroundColor: '#E2E8F0' },
}

export const LabelCheckboxStyle = {
  labelStyle: {
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: '400',
    color: '#2D3748',
  },
}
