export const useCentrifuge = () => {
  // @ts-ignore
  let centrifuge = new Centrifuge(process.env.VUE_APP_WSS)
  const addChanel = (channel: string, callback: (data: any) => any) => {
    centrifuge.subscribe(channel, function (ctx: any) {
      callback(ctx.data)
    })
  }

  const startCentrifuge = () => {
    // @ts-ignore
    centrifuge = new Centrifuge(process.env.VUE_APP_WSS)
    centrifuge.on('connect', function (ctx: any) {
      return
    })

    centrifuge.on('disconnect', function (ctx: any) {
      return
    })

    centrifuge.connect()
  }

  return { addChanel, centrifuge, startCentrifuge }
}
