import { defineStore } from 'pinia'
import { roleMap } from '@/helpers/scripts'

/*
 * Статус верификации для модального окна ЛК
 * verificationStatus: none | full | part
 * */
export const useStore = defineStore('main', {
  state: () => {
    return {
      user: null,
      isOpenCart: false,
      toMain: false,
      toHistory: false,
      verificationStatus: 'none',
      isVerification: false,
      userRole: '',
      previousRoute: '',
    }
  },
  getters: {
    getOpenCart(): boolean {
      return this.isOpenCart
    },
    getUserRole(): string {
      return this.userRole
    },
  },
  actions: {
    setUserRole(role: string) {
      this.userRole = roleMap[role] || ''
    },
    toggleOpenCart() {
      this.isOpenCart = !this.isOpenCart
      this.closeModals()
    },
    closeOpenCart() {
      this.isOpenCart = true
      this.closeModals()
    },
    closeModals() {
      const modals = Array.from(document.getElementsByClassName('product'))
      if (this.isOpenCart) {
        modals.forEach((modal) => modal.classList.add('display-none'))
      }
      if (!this.isOpenCart) {
        modals.forEach((modal) => modal.classList.remove('display-none'))
      }
    },
    toMainFromThirdStep() {
      this.toMain = !this.toMain
    },
    toHistoryFromThirdStep() {
      this.toHistory = !this.toHistory
    },
    setVerificationStatus(status: string) {
      this.verificationStatus = status
    },
    getIsVerification(): boolean {
      return this.isVerification
    },
    setIsVerification(status: any) {
      this.isVerification = status || false
    },
    setPreviousRoute(route: string) {
      this.previousRoute = route
    },
    setUser(data: any) {
      this.user = data
    },
  },
})
