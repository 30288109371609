import { RoleMap, Validator } from '@/types/types'
import { Roles } from '@/types/enums'

/**
 * Calls the passed validators and writes the result
 * @param validators
 * @param value
 * @return {string}
 */
export const validate = (validators: any, value: any) => {
  if (validators.length) {
    let result
    for (const validator of validators) {
      if (validator === undefined) continue
      result = validator(value)
      if (result !== '') return result
    }
  }
  return ''
}

export function required(value: string): string {
  return !value ? 'Обязательное поле' : ''
}

export function requiredWithText(text: string): Validator {
  return (value: string): string => {
    return !value ? text : ''
  }
}

export function equal(checkWith: string): Validator {
  return (value: string): string => {
    return value !== checkWith ? `Пароли не совпадают` : ''
  }
}

export function maxString(max: number): Validator {
  return (value: string): string => {
    return value.length > max ? `Максимум ${max} символов` : ''
  }
}

export function minString(min: number): Validator {
  return (value: string): string => {
    return value.length < min ? `Минимум ${min} символов` : ''
  }
}

export function minPhoneString(min: number): Validator {
  return (value: string): string => {
    return value.length < min ? `Минимум 11 цифр` : ''
  }
}

export function maxValue(max: number): Validator {
  return (value: string | number): string => {
    return Number(value) > max ? `Максимальное значение ${max}` : ''
  }
}

export function maxValueStrToNumber(max: number): Validator {
  return (value: string): string => {
    return Number(value.split(' ').join('')) > max
      ? `Максимальное значение ${max}`
      : ''
  }
}

export function minValue(min: number): Validator {
  return (value: string | number): string => {
    return Number(value) < min ? `Минимальное значение ${min}` : ''
  }
}

export function format(
  format: RegExp,
  errorText = 'Некорректный формат',
): Validator {
  return (value: string): string => {
    return !format.test(value) ? errorText : ''
  }
}

export function onlyNumber(value: string): string {
  if (value) {
    return !/^-?\d+[.,-]?\d*$/.test(value)
      ? 'Поле может содержать только цифры'
      : ''
  } else return ''
}

export function onlyNonZeroNumber(value: string): string {
  onlyNumber(value)
  if (value[0] === '-') {
    return 'Поле не может быть отрицательным'
  }
  return ''
}

export function constLength(maxLen: number): Validator {
  return (value: string): string => {
    return value && value.length !== maxLen
      ? `Поле должно содержать ${maxLen} символов`
      : ''
  }
}

export function constBorders(left: number, right: number): Validator {
  return (value: string): string => {
    return value && value.length !== left && value.length !== right
      ? `Поле должно содержать ${left} или ${right} символов`
      : ''
  }
}

// Валидация количества товара в корзине покупателем
export const cartMinValue = (min: number, item: any): Validator => {
  return (value: string | number): string => {
    return Number(value) < Number(min)
      ? `Минимальный заказ от ${min} ${item.measurement_unit_id}`
      : ''
  }
}

export const cartMaxValue = (max: number, item: any): Validator => {
  return (value: string | number): string => {
    // if (Number(value) > 100000000) {
    //   return `Количество не может быть больше 100 000 000 ${item.measurement_unit}`
    // } else
    if (Number(value) > Number(max)) {
      return `Максимальный заказ до ${max} ${item.measurement_unit_id}`
    } else {
      return ''
    }
  }
}

export const limitChartersComma = (): Validator => {
  return (value: string | number): string => {
    if (value.toString().includes('.')) {
      const parts = value.toString().split('.')
      if (parts[1].length > 2) {
        parts[1] = parts[1].slice(0, 2)
        return 'Количество символов после запятой не должно превышать двух'
      }
    }
    return ''
  }
}

export const maxValueValidator = (max: number): Validator => {
  return (value: string | number): string => {
    return Number(value) > max ? `Максимальное значение ${max}` : ''
  }
}

export const minValueValidator = (min: number): Validator => {
  return (value: string | number): string => {
    return Number(value) < min ? `Минимальное значение ${min}` : ''
  }
}

export const maxCartValueValidator = (max: number): Validator => {
  return (value: string | number): string => {
    return Number(Number(value).toFixed(2)) > max
      ? `Максимальный заказ до ${max}`
      : ''
  }
}

export const minCartValueValidator = (min: number): Validator => {
  return (value: string | number): string => {
    return Number(Number(value).toFixed(2)) < min
      ? `Минимальный заказ от ${min}`
      : ''
  }
}

export function onlyOneDot(value: string): string {
  if (value) {
    const dotCount = (value.match(/\./g) || []).length
    if (dotCount > 1 || (dotCount === 1 && value.charAt(0) === '.')) {
      return 'Поле может содержать только одну точку и не может начинаться с нее'
    }
  }
  return ''
}

export function firstDot(value: string): string {
  if (value && value.charAt(0) === '.') {
    return 'Поле не может начинаться с точки'
  }
  return ''
}

export const roleMap: RoleMap = {
  [Roles['customer.no_personal_info']]: Roles['customer.no_personal_info'],
  [Roles['farmer.no_personal_info']]: Roles['farmer.no_personal_info'],
  [Roles['user.confirmed']]: Roles['user.confirmed'],
  [Roles.Farmer]: Roles.Farmer,
  [Roles.Customer]: Roles.Customer,
  [Roles.Admin]: Roles.Admin,
}
