import { defineStore } from 'pinia'
import { CatalogAPI } from '@/api/CatalogAPI'
import { IParameter } from '@/types/interfaces'

interface catalogStoreState {
  catalogList: any
  currentCategory: []
  current: object
  productsCategory: any
  filters: ICatalogFilters[]
  titleCategory: string
  filterFirstPart: string
  selectedFilters: []
  filterPart: string[]
  page: number
  idSubCategory: null | number
  isCategoryFetching: boolean
  isLoad: boolean
  isOpen: boolean
  isMoreFetching: boolean
  isRefetching: boolean
  scrollPosition: number
  needToScroll: boolean
}

export interface ICatalogFilters extends IParameter {
  disabled: boolean
  rules: string[]
  values: string[]
}

export const catalogStore = defineStore('catalogStore', {
  state: (): catalogStoreState => {
    return {
      catalogList: [],
      idSubCategory: null,
      currentCategory: [],
      current: {},
      productsCategory: [],
      filters: [],
      page: 1,
      titleCategory: '',
      filterFirstPart: '',
      selectedFilters: [],
      filterPart: [],
      isCategoryFetching: false,
      isLoad: false,
      isOpen: false,
      isMoreFetching: false,
      isRefetching: false,
      scrollPosition: 0,
      needToScroll: false,
    }
  },
  persist: true,
  actions: {
    async getCategoriesCatalog() {
      await CatalogAPI.getCategories().then((resp: any) => {
        this.catalogList = resp.items
        const firstActive = resp.items.find((el: any) => el.is_active)
        this.current = firstActive
        this.getCategory(firstActive.id)
      })
    },
    async getCategory(id: number) {
      this.isCategoryFetching = true
      await CatalogAPI.getCategory(id).then((resp) => {
        this.currentCategory = resp.items.filter((item: any) => item.is_active)
        this.current = this.catalogList.filter((el: any) => el.id === id)[0]
        this.isCategoryFetching = false
      })
    },
    async getProducts(id: number) {
      this.isRefetching = true
      await CatalogAPI.getProductsByCategory(id, 1).then((resp) => {
        this.productsCategory = resp.items
        this.titleCategory =
          resp.items[0]?.fullNameWithoutParametersAndWithoutMainCategory
        this.filterFirstPart = resp.items[0]?.fullNameWithoutParameters
        this.isRefetching = false
      })
      await CatalogAPI.getFilters(id).then((resp) => {
        this.filters = resp.items[0].parameters.map((item: IParameter) => ({
          ...item,
          rules: item.rule.split('|').map(function (str) {
            return str.replace(/[\r\nm$^/]/gm, '')
          }),
          values: item.type === 'binary' ? ['нет'] : [],
          disabled: false,
          related_rule: item.related_rule?.replace(/[\r\nm$^/]/gm, '') ?? null,
        }))
      })
    },

    async getProductsWithFilter(id: number, page: number, filters: any = []) {
      if (page > 1) {
        this.isMoreFetching = true
      } else {
        this.isMoreFetching = false
        this.isRefetching = true
      }
      if (!filters[0].length) {
        await CatalogAPI.getProductsByCategory(id, page).then((resp) => {
          this.isLoad = resp.items.length > 0
          if (page === 1) {
            this.productsCategory = resp.items
          } else {
            this.productsCategory.push(...resp.items)
          }

          this.isMoreFetching = false
          this.isRefetching = false
        })
      } else {
        await CatalogAPI.getProductsWithFilterCategory(id, page, filters).then(
          (resp: any) => {
            this.isLoad = resp.items.length > 0
            if (page === 1) {
              this.productsCategory = resp.items
            } else {
              this.productsCategory.push(...resp.items)
            }

            this.isMoreFetching = false
            this.isRefetching = false
          },
        )
      }
    },
    setScrollPosition(scrollY: number) {
      this.scrollPosition = scrollY
    },
    toggleScroll() {
      this.needToScroll = !this.needToScroll
    },
  },
  getters: {
    getCategories: (state) => state.catalogList,
    getProductsItems: (state) => state.productsCategory,
    getFilters: (state) => state.filters,
    getPage: (state) => state.page,
  },
})
