import EgalActionConstructor from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'

class CatalogAPIModel {
  async getPosition(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.Product,
      )
        .filter([['id', 'eq', id]])
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getProduct(id: number, per_page = 10, page = 0): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.FarmerProduct,
      )
        .withs([
          'farmer.company_rating',
          'farmer',
          'farmer.addresses',
          'specialPrices.customer',
          'priceOptions',
        ])
        .filter([
          ['farmer.is_active', 'eq', true],
          ['is_deleted', 'eq', false],
          ['product_id', 'eq', id],
        ])
        .order([['fullname', 'asc']])
        .setPagination(per_page, page)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getMinMaxPriceProduct(
    id: number,
    priceType: 'max' | 'min',
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.FarmerProduct,
      )
        .withs(['farmer', 'farmer.addresses'])
        .filter([[String(id), 'minMaxPrice', priceType]])
        .order([['fullname', 'asc']])
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getPartner(farmer_id: number, customer_id: number, filters?: []): Promise<any> {
    return new Promise((resolve, reject) => {
      const filters = []

      if (farmer_id) {
        filters.push(['farmer_id', 'eq', farmer_id])
      }

      if (customer_id) {
        filters.push(['customer_id', 'eq', customer_id])
      }

      return EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.Partner,
      )
          // TODO: Перепроверить
/*          .filter(
              filters
                  ? filters
                  : [
                      ['farmer_id', 'eq', farmer_id],
                      ['customer_id', 'eq', customer_id],
                  ],
          )*/
        .filter(filters)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getCategories(): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.ProductCategoryOptimized,
      )
        .order([['name', 'desc']])
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  async getCategory(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.ProductCategoryOptimized,
      )
        .filter([[id.toString()]])
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  async getFilters(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.Category,
      )
        .filter([['id', 'eq', id]])
        .withs(['parameters'])
        .setPagination(100, 1)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  async getProductsByCategory(
    id: number,
    page = 1,
    filters: any[][] = [],
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.Product,
      )
        .filter([
          ['product_category_id', 'eq', id],
          ['count', 'ge', 1],
          [...filters],
        ])
        .withs(['product_parameters'])
        .setPagination(25, page)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  async getProductsWithFilterCategory(
    id: number,
    page = 1,
    filters: any,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.Product,
      )
        .filter(
          [['farmer_products.is_deleted', 'eq', false], 'AND', ...filters],
          'custom',
        )
        .withs(['product_parameters'])
        .setPagination(25, page)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const CatalogAPI = new CatalogAPIModel()
export { CatalogAPI }
