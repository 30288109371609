import { defineStore } from 'pinia'
import { CartAPI } from '@/api/CartAPI'

interface OrderStoreState {
  orders: any[]
  showCanDelivery: boolean
  totalAmount: number
}

export const useOrderStore = defineStore('orderStore', {
  state: (): OrderStoreState => {
    return {
      orders: [],
      showCanDelivery: false,
      totalAmount: 0,
    }
  },
  persist: true,

  actions: {
    async getCartAmountStore() {
      this.totalAmount = 0
      const response = await CartAPI.getCartDataStore(1, 100, ['user'])
      this.orders = response.items.map((item: any) => {
        return {
          id: item.farmer_product_id,
          quantity: item.quantity,
          idFromDelete: item.id,
        }
      })
      this.totalAmount = response.total_count
    },

    async addToCartStore(productID: number, minValue: number) {
      await CartAPI.createCart(productID, minValue)
      await this.getCartAmountStore()
    },

    async deleteCartItemStore(productID: number) {
      await CartAPI.deleteCartItem(
        this.getOrders.find((i) => i.id === productID).idFromDelete,
      )
      await this.getCartAmountStore()
    },
    async deleteManyCartItemsStore(ids: string[]) {
      await CartAPI.deleteManyCartItem(ids)
      await this.getCartAmountStore()
    },
    async clearCartStore() {
      this.totalAmount = 0
      this.orders = []
    },
    //todo

    addItemToCart(item: any) {
      this.orders.push({ ...item, checked: true })
    },

    removeItemFromCart(item: any) {
      this.orders = this.orders.filter((order) => order.id !== item.id)
    },

    updateOrderQuantity(item: any, quantity: string | number) {
      this.orders = this.orders.map((i) => {
        if (i.id === item.id) {
          // i.new_quantity = quantity
          return { ...i, new_quantity: quantity }
        }

        return i
      })
    },

    updateOrder(item: any) {
      this.orders.forEach((obj1) => {
        const obj2 = item.find((obj2: any) => obj2.id === obj1.id)
        if (obj2) {
          obj1.quantity = obj2.quantity
          obj1.price = obj2.price
          obj1.prepare_days = obj2.prepare_days
          obj1.min_value = obj2.min_value
          obj1.farmer.can_deliver = obj2.farmer.can_deliver
          obj1.farmer.address = obj2.farmer.address
        }
      })

      this.orders = this.orders.filter((obj1) =>
        item.some((obj2: any) => obj2.id === obj1.id),
      )
    },

    addOrderQuantity(item: any, quantity: string | number) {
      this.orders.map((i) => {
        if (i.id === item.id) {
          i.quantity += Number(quantity)
        }
      })
    },
    clearCart() {
      this.orders = []
    },
    clearCheckedCart() {
      if (this.showCanDelivery) {
        this.orders = this.orders.filter(
          (order) => !order.farmer.can_deliver && order.checked,
        )
      } else {
        this.orders = this.orders.filter((order) => !order.checked)
      }
    },
    toggleCheckOrder(item: any) {
      this.orders.map((i) => {
        if (i.id === item.id) {
          i.checked = !i.checked
        }
      })
    },
    setShowCanDelivery(canDelivery: boolean) {
      this.showCanDelivery = canDelivery
    },
  },
  getters: {
    getOrdersAmount(state) {
      return state.totalAmount
    },
    getOrders(state) {
      return state.orders
    },
    getOrdersWithValueError(state) {
      return state.orders.filter((order) => order.cartValueError).length > 0
    },
    getOrdersWithValueErrorAndChecked(state) {
      return (
        state.orders.filter((order) => order.cartValueError && order.checked)
          .length > 0
      )
    },
    getShowCanDelivery(state) {
      return (
        state.showCanDelivery &&
        state.orders.filter(
          (order) => !order.farmer.can_deliver && order.checked,
        ).length > 0
      )
    },
    getOrdersChecked(state) {
      return state.orders.find((order) => order.checked)
    },
  },
})
