import EgalActionConstructor from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'

class NotificationAPIModel {
  async contactGetItems(): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItems(
        Microservices.Core,
        APIModels.Notification,
      )
        .order([['created_at', 'asc']])
        .setPagination(1000, 1)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async updateNotification(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.update(
        Microservices.Core,
        APIModels.Notification,
        data,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const NotificationAPI = new NotificationAPIModel()
export { NotificationAPI }
