import { defineStore } from 'pinia'
import { ChatAPI } from '@/api/ChatAPI'
import router from '@/router'

interface IChatStore {
  customerUids: string[]
  farmerUids: string[]
  uids: string[]
}

export const chatStore = defineStore('chatStore', {
  state: (): IChatStore => ({
    customerUids: [],
    farmerUids: [],
    uids: [],
  }),
  persist: false,
  actions: {
    async createChat(name: string) {
      if (this.farmerUids?.length && this.customerUids?.length) {
        await ChatAPI.createChannel({
          name: name,
          user_ids: [...this.customerUids, ...this.farmerUids],
        })
          .then((res) => {
            const chatId =
              res?.data?.action_result?.data.id ||
              res?.data?.action_result?.data.channel_id
            if (chatId) {
              router.push(`/chat/${chatId}`)
            }
          })
          .catch((error) => {
            console.log('createChat error', error)
          })
      }
    },
    async getCustomersIds(customerId: number) {
      await ChatAPI.getCustomer(customerId, ['users']).then(async (res) => {
        const arr = res?.users?.map((user: any) => user?.id)
        this.customerUids = [...new Set(this.uids.concat(arr))]
        const currentCompanyId = res?.users[0]?.company_id
        if (currentCompanyId) {
          localStorage.setItem('company_id', currentCompanyId)
        }
      })
    },
    setFarmerUIDs(ids: string[]) {
      this.farmerUids = [...new Set(this.uids.concat(ids))]
    },
  },
})
